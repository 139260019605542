import React from 'react';
import styled from 'styled-components';

import BannerLogo from './BannerLogo';

const BannerWrap = styled.div`
  display: flex;
  flex-basis: 50%;
  background-color: ${props => props.backgroundColor};

  &:first-child {
    justify-content: right;
  }
  &:last-child {
    justify-content: left;
  }
`;

const BannerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 720px;
`;

const BannerContext = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 291px;
  padding-bottom: ${props => props.logo.padding}px;
`;

const BannerTitle = styled.h1`
  height: 57px;
  font-size: 50px;
  font-weight: bold;
  color: ${props => props.mainColor};
  line-height: normal;
`;

const BannerSubTitle = styled.h2`
  height: 30px;
  color: ${props => props.mainColor};
  margin-top: 15px;
  font-size: 18px;
  line-height: 1.67;
`

const BannerLinkButton = styled.button`
  height: 46px;
  margin-top: 40px;
  padding: 12px 14px;
  border: 1px solid ${props => props.color.prim};
  border-radius: 6px;
  font-size: 20px;
  background-color: inherit;
  color: ${props => props.color.prim};
`;

const BannerImage = styled.div`
  background-image: url(${props => props.bannerImage});
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  max-width: ${props => props.imageSize.width}px;
  height: 100%;
  max-height: ${props => props.imageSize.height}px;
`

const Banner = ({
  logo,
  url = '',
  title,
  subTitle,
  color,
  bannerImage,
  imageSize,
  onWebsite = () => {}
}) => {
  return (
    <BannerWrap className="desktop" backgroundColor={color?.background}>
      <BannerBox>
        <BannerContext>
          <BannerLogoContainer logo={logo}>
            <BannerLogo logo={logo}/>
          </BannerLogoContainer>
          <BannerTitle mainColor={color?.prim}>{title}</BannerTitle>
          <BannerSubTitle mainColor={color?.prim}>{subTitle}</BannerSubTitle>
          <BannerLinkButton color={color} onClick={() => onWebsite(url)}>{title} 사이트 방문</BannerLinkButton>
        </BannerContext>
        <BannerImage 
          bannerImage={bannerImage}
          imageSize={imageSize}
        ></BannerImage>
      </BannerBox>
    </BannerWrap>
  );
};

export default Banner;