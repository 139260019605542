import React from 'react';
import styled from 'styled-components';

import BannerSmall from '../components/BannerSmall';

import logoCallSmall from '../assets/images/img-logo-openpay.svg';
import logoRentSmall from '../assets/images/img-logo-rent.svg';

const Type_1 = "type1";
const Type_2 = "type2";

const SmallBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const BannerContainerSmall = ({ image: {
    bannerCall, logoCall, bannerRent, logoRent
  },
  deviceSize,
  onWebsite = () => {}
}) => {
  const {width, height} = deviceSize;
  const type = width*2 > height ? Type_1 : Type_2;

  return (
    <SmallBannerContainer>
      <BannerSmall 
        type={type}
        color={{
          prim: 'white',
          seco: '#1d9bf7',
          background: '#1d9bf7',
        }}
        bannerImage={bannerCall}
        imageSize={{ width: 660, height: 376 }}
        logo={{ img: type===Type_2 ? logoCall : logoCallSmall, padding: 33, width: 140, height: 40 }}
        title="오픈 페이 콜"
        subTitle="배달비용 카드결제서비스"
        url="https://call.open-pay.co.kr/"
        onWebsite={onWebsite}
      />
      <BannerSmall 
        type={type}
        color={{
          prim: '#1d9bf7',
          seco: 'white',
          background: 'white',
        }}
        bannerImage={bannerRent}
        imageSize={{ width: 630, height: 369 }}
        logo={{ img: type===Type_2 ? logoRent : logoRentSmall, padding: 30, width: 140 }}
        title="오픈 페이 월세"
        subTitle="임대료, 관리비 카드결제서비스"
        url="https://rent.open-pay.co.kr/"
        onWebsite={onWebsite}
      />
    </SmallBannerContainer>
  );
};

export default BannerContainerSmall;