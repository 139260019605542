import React from 'react';
import styled from 'styled-components';

import Logo from '../components/Logo'

const BannerWrap = styled.div`
  display: flex;
  flex-basis: 50%;
  padding: 20px;
  background-color: ${props => props.backgroundColor};
  background-image: url(${props => props.bannerImage});
  background-size: calc(100% - 30px);
  background-position: center bottom;
  background-repeat: no-repeat;
`;

const BannerMainBox = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex-direction: ${props => props.type === 'type1' ? 'row' : 'column'};
  width: 100%;
  /* justify-content: space-between; */
  justify-content: ${props => props.type === 'type1' ? 'space-between' : 'flex-start'};
`;

const BannerContentsBox = styled.div`
  text-align: ${props => props.type === 'type1' ? 'left' : 'center'};
`;

const BannerContentsText = styled.div`
  margin-top: ${props => props.type === 'type1' ? '7px' : '7px'};
  margin-bottom: ${props => props.type === 'type1' ? '0' : '4px'};
  font-size: 18px;
  color: ${({ color }) => color.prim};
  line-height: normal;
`;

const BannerSubTitle = styled.h2`
  height: 15px;
  margin-top: 4px;
  font-size: 10px;
  line-height: 1.5;
  color: ${({ color }) => color.prim};
`

const BannerButtonBox = styled.div`
  margin-top: ${props => props.type === 'type1' ? '0' : '21px'};
`;

const BannerButton = styled.button`
  overflow: hidden;
  height:25px;
  padding: 6px 8px;
  border: 1px solid ${props => props.color.prim};
  border-radius: 3px;
  font-size: 12px;
  background-color: inherit;
  color: ${props => props.color.prim};
`;

const BannerSmall = ({
  type = "type1",
  url = "",
  logo,
  title,
  subTitle,
  color,
  bannerImage,
  imageSize,
  onWebsite = () => { console.log('undefined func onWebsite') }
}) => {
  return (
    <BannerWrap backgroundColor={color.background} bannerImage={bannerImage}>
      <BannerMainBox type={type}>
        <BannerContentsBox type={type}>
          <Logo logo={logo}/>
          <BannerContentsText type={type} color={color}>{title}</BannerContentsText>
          <BannerSubTitle type={type} color={color}>{subTitle}</BannerSubTitle>
        </BannerContentsBox>
        <BannerButtonBox>
          <BannerButton onClick={()=>onWebsite(url)} color={color} type={type}>{title} 사이트 방문</BannerButton>
        </BannerButtonBox>
      </BannerMainBox>
    </BannerWrap>
  );
};

export default BannerSmall;