import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div``;

const LogoItem = styled.img.attrs(props => ({
  src: `${props.logo.img}`,
}))`
  width: ${props => props.logo.width+'px' || 'auto'};
  height: auto;
`;

const Logo = ({logo}) => {
  return (
    <LogoContainer>
      <LogoItem logo={logo}/>
    </LogoContainer>
  );
};

export default Logo;