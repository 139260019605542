import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components';

import Banner from '../../components/Banner';
import SmallBannerContainer from '../../containers/BannerContainerSmall'

import bannerCall from '../../assets/images/img-main-call.svg';
import logoCall from '../../assets/images/img-logo-openpay.svg';

import bannerRent from '../../assets/images/img-main-rent.svg';
import logoRent from '../../assets/images/img-logo-rent.svg';

const BannerContainer = styled.div`
  display: flex;
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  height: 100%;

  /* @media (max-width: 1024px) {
    flex-direction: column;
  } */
`;

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Home = () => {
  const [width, height] = useWindowSize([0, 0]);

  const onWebsite = url => {
    window.location.href = url;
  }

  return width > 1024 ? (
    <BannerContainer>
      <Banner
        color={{
          prim: 'white',
          seco: '#1d9bf7',
          background: '#1d9bf7',
        }}
        bannerImage={bannerCall}
        imageSize={{ width: 660, height: 376 }}
        logo={{ img: logoCall, padding: 33 }}
        title="오픈 페이 콜"
        subTitle="배달비용 카드결제서비스"
        onWebsite={onWebsite}
        url="https://call.open-pay.co.kr/"
      />
      <Banner 
        color={{
          prim: '#1d9bf7',
          seco: 'white',
          background: 'white',
        }}
        bannerImage={bannerRent}
        imageSize={{ width: 630, height: 369 }}
        logo={{ img: logoRent, padding: 30 }}
        title="오픈 페이 월세"
        subTitle="임대료, 관리비 카드결제서비스"
        onWebsite={onWebsite}
        url="https://rent.open-pay.co.kr/"
      />
    </BannerContainer>
  ) : (
    <SmallBannerContainer 
      image={{
        bannerCall,
        logoCall,
        bannerRent,
        logoRent,
      }}
      deviceSize={{ width, height }}
      onWebsite={onWebsite}
    />
  );
};

export default Home;