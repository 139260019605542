import Home from './domains/Home'

import './assets/fonts/index.css'
import './App.css';



function App() {
  console.log(`App version 1.1.4`)

  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
